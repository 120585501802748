main {
  margin: 0 auto;
  max-width: 90%;
  padding: 50px 30px;
}
:root {
  --primary: #7fa99b;
  --secondary: #7fa99b;
  --tertiary: #a9b2ac;
  --light: #ffffff;
  --dark: #1e1b18;
  --darker: #1e1b18;
  --error: rgb(228, 46, 1);
  --spacing-one: calc(1rem * 0.5);
  --spacing-two: calc(1rem);
  --spacing-five: calc(2.5rem);
}

* {
  box-sizing: border-box;
}

html,
body {
  min-height: 100%;
}

body {
  margin: 0;
  padding: 0;
  /* font-weight: 600; */
  line-height: 1.5;
  font-size: 18px;
  letter-spacing: 0.1em;
  background: var(--dark);
  color: var(--light);
  font-family: "Lucida Console", monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Lucida Console", monospace;
  font-weight: 500;
  margin: 5px 0;
  line-height: 1.25;
}

h1 {
  font-size: 2.25rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.75rem;
  color: var(--tertiary);
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.25rem;
}

h6 {
  font-size: 1rem;
}

p {
  font-size: 1.1rem;
  margin-top: 0;
}

a {
  color: var(--secondary);
  text-decoration: none;
  font-weight: bold;
  line-height: 1.5;
  font-size: 22px;
  letter-spacing: 0.1em;
}

a:hover {
  text-decoration: underline;
}

header {
  background: var(--dark);
}

header h1 {
  color: var(--tertiary);
}

header a {
  color: var(--light);
}

button {
  font-size: 1rem;
  font-weight: 600;
  text-decoration: none;
  border-radius: 0.5rem;
  border: none;
  color: var(--darker);
  outline: none;
  cursor: pointer;
  margin: 0 1px;
  background-color: var(--secondary);
  padding: 10px 15px;
}

button:hover {
  opacity: 0.8;
}

button[disabled] {
  cursor: default;
  opacity: 0.8;
}

img {
  max-width: 100%;
}

span[role="img"] {
  cursor: default;
}

form {
  width: 40%;
}

input {
  margin: 10px 0;
  font-size: 1rem;
  padding: 0.25rem;
  width: 600px;
  border: 1px solid var(--dark);
  outline: none;
  border-radius: 3px;
  transition: box-shadow 0.2s;
}

input:focus {
  border: 1px solid var(--secondary);
  box-shadow: 0 0 0.25rem rgba(0, 0, 0, 0.5);
}

label {
  margin-right: 10px;
  color: var(--tertiary);
}

li {
  list-style: none;
}

nav {
  font-size: 1.5rem;
}

nav a:hover {
  text-decoration: none;
}

nav li {
  cursor: pointer;
  margin: 0 50px;
}

textarea {
  width: 600px;
  height: 200px;
  border: 3px solid #cccccc;
  padding: 5px;
  margin-top: 10px;
}

footer {
  font-size: 48px;
  justify-content: center;
}

footer a {
  color: var(--secondary);
  opacity: 0.5;
  margin: 10px 20px;
  transition: all 0.3s ease-in-out;
  font-size: 40px;
}

footer a:hover {
  opacity: 1;
}

/* HERO STYLES START */
.hero {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}

.hero-img img {
  max-width: 300px;
  padding: 10px;
}

.hero-intro {
  width: 30%;
  text-align: left;
  margin: 5%;
  font-size: 24px;
  line-height: 1.2;
  color: var(--light);
}

.hero-intro h2 {
  color: var(--secondary);
}

/* SECTION TITLE STYLES START */
.section-title {
  font-size: 36px;
  text-align: center;
  margin: 0 auto 20px auto;
  width: 50%;
  color: var(--secondary);
}

/* ABOUT ME STYLES START */
.about {
  margin-top: 20px;
}

.about p {
  color: var(--tertiary);
  width: 80%;
  margin: 0 auto;
  margin-bottom: 20px;
  font-size: 20px;
  text-align: center;
}
/* ABOUT ME STYLES END */

.container {
  width: 85%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

.flex-row {
  display: flex;
  flex-wrap: wrap;
}

.space-between {
  justify-content: space-between;
}

.flex-end {
  justify-content: flex-end;
}

.mx-1 {
  margin-right: var(--spacing-one);
  margin-left: var(--spacing-one);
}

.mx-2 {
  margin-right: var(--spacing-two);
  margin-left: var(--spacing-two);
}

.my-1 {
  margin-top: var(--spacing-one);
  margin-bottom: var(--spacing-one);
}

.my-2 {
  margin-top: var(--spacing-two);
  margin-bottom: var(--spacing-two);
}

.my-5 {
  margin-top: var(--spacing-five);
  margin-bottom: var(--spacing-five);
}

.px-1 {
  padding-right: var(--spacing-one);
  padding-left: var(--spacing-one);
}

.px-2 {
  padding-right: var(--spacing-two);
  padding-left: var(--spacing-two);
}

.py-1 {
  padding-top: var(--spacing-one);
  padding-bottom: var(--spacing-one);
}

.py-2 {
  padding-top: var(--spacing-two);
  padding-bottom: var(--spacing-two);
}

.card {
  width: 25%;
  text-align: center;
}

.project {
  position: relative;
  width: 100%;
  max-width: 500px;
  height: 300px;
  margin: 30px;
}

.project-bg {
  position: relative;
  width: 100%;
  height: 100%;
}

.project-text {
  color: var(--secondary);
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  padding: 50px;
  z-index: 900;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

.project-text:hover {
  background-color: var(--alpha);
  opacity: 1;
  border: 10px solid var(--secondary);
}

.project-text h3 {
  font-size: 2.5rem;
}

.project-text a {
  text-decoration: none;
  font-weight: bold;
  font-size: 24px;
  padding: 10px;
  color: var(--dark);
  transition: all 0.3s ease-in-out;
  background-color: var(--secondary);
}

.project-text a:hover {
  color: var(--darker);
}

.label {
  display: inline-block;
  vertical-align: top;
  position: relative;
  margin: 20px;
  border: var(--secondary-color);
  -webkit-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5),
    0px 10px 7px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5),
    0px 10px 7px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5), 0px 10px 7px rgba(0, 0, 0, 0.2);
}

.img-thumbnail {
  width: auto;
  height: 300px;
  margin-bottom: 50px;
  cursor: pointer;
}

.modalBackdrop {
  background: rgba(0, 0, 0, 0.65);
  bottom: 0;
  left: 0;
  overflow: auto;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1;
}

.modalContainer {
  background: var(--darker);
  border-radius: 5px;
  max-width: 100%;
  margin: 50px auto;
  padding: 15px;
  width: 960px;
}

.modalTitle {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 25px;
}

.navActive {
  color: var(--secondary);
}
